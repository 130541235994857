<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="결재요청 정보" class="cardClassDetailForm" topClass="topcolor-orange">
            <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="col-12">
                    <c-text
                      :editable="false"
                      label="결재 업무명"
                      name="approvalTypeName"
                      v-model="data.approvalTypeName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="false"
                      label="결재 요청명"
                      name="approvalRequestName"
                      v-model="data.approvalRequestName">
                    </c-text>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:-15px !important;">
                  <div class="appr-line-detail-layer2">
                    <c-card title="결재선" class="cardClassDetailFormLine" topClass="topcolor-orange">
                      <template slot="card-detail">
                        <div class="col-12">
                          <draggable
                            tag="ul"
                            :list="gridline.data"
                            class="list-group"
                            handle=".handle"
                            v-bind="dragOptions"
                          >
                            <transition-group type="transition" name="flip-list">
                              <li
                                class="list-group-item"
                                v-for="(element, idx) in gridline.data"
                                :key="element.approvalUserId + idx + element.approvalKindCd"
                              >
                                <i class="material-icons linehandlex no-move">reorder</i>
                                <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                                <div class="linetext linespan12" :class="getLineKindColorStatus(element.approvalStatusCd)">{{ element.approvalStatusName }}</div>
                                <div class="linetext linespan13">{{ element.deptName }}</div>
                                <div class="linetext linespan13">{{ element.approvalUserName }}</div>
                                <div class="linetext linespanall" v-if="element.returnReason">반려사유 : {{ element.returnReason }}</div>
                                <div class="linetext linespanall" v-if="element.approvalStatusCd == 'ASC0000001'"> 
                                  <c-text
                                    :editable="true"
                                    placeholder="반려사유"
                                    label=""
                                    name="returnReasonText"
                                    v-model="returnReasonText">
                                  </c-text>
                                </div>
                                <div class="linetext linespanall" v-if="element.approvalDt">
                                  <q-btn 
                                    round unelevated 
                                    size="8px"
                                    color="amber" 
                                    icon="search"
                                    @click.stop="() => { rowIndex = idx, electronSignature = element.electronSignature}"
                                    >
                                    <q-popup-proxy :ref="'proxy1_' + idx" :breakpoint="400">
                                      <component
                                        :is="componentSigh"
                                        :popupParam="element"
                                        :rowIndex="idx"
                                        @callback="callback1"
                                      />
                                    </q-popup-proxy>
                                  </q-btn>
                                  &nbsp;&nbsp;&nbsp;&nbsp;{{ element.approvalDt }}
                                </div>
                              </li>
                            </transition-group>
                          </draggable>
                        </div>
                      </template>
                    </c-card>
                    <c-card title="회람" class="cardClassDetailFormLine" topClass="topcolor-orange">
                      <template slot="card-detail">
                        <div class="col-12">
                          <draggable
                            tag="ul"
                            :list="gridline2.data"
                            class="list-group"
                            handle=".handle"
                            v-bind="dragOptions"
                          >
                            <transition-group type="transition" name="flip-list">
                              <li
                                class="list-group-item"
                                v-for="(element, idx) in gridline2.data"
                                :key="element.approvalUserId + idx + element.approvalKindCd"
                              >
                                <i class="material-icons linehandlex no-move">reorder</i>
                                <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                                <div class="linetext linespan2">{{ element.deptName }}</div>
                                <div class="linetext linespan3">{{element.approvalUserName}}</div>
                              </li>
                            </transition-group>
                          </draggable>
                        </div>
                      </template>
                    </c-card>
                  </div>
                  <!-- <c-mobile-table
                    ref="apprline"
                    title="결재선"
                    :columns="gridline.columns"
                    :gridHeightAuto="true"
                    :data="gridline.data"
                    :filtering="false"
                    :columnSetting="false"
                    :usePaging="false"
                  >
                    <template v-slot:customArea="{ props, col }">
                      <template v-if="col.name === 'approvalKindName'">
                        <div class="apprGridTxt" :class="getLineKindColor(props.row.approvalKindCd)">{{props.row.approvalKindName}}</div>
                      </template>
                      <template v-if="col.name === 'approvalStatusName'">
                        <div class="apprGridTxt2" :class="getLineKindColor2(props.row.approvalStatusName)">{{props.row.approvalStatusName}}</div>
                      </template>
                      <template v-if="col.name === 'click'">
                        <q-btn 
                          round unelevated 
                          size="10px"
                          color="amber" 
                          icon="search"
                          @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                          >
                          <q-popup-proxy :ref="'proxy1_' + props.rowIndex" :breakpoint="400">
                            <component
                              :is="col.component"
                              :popupParam="props.row"
                              :rowIndex="props.rowIndex"
                              @callback="callback1"
                            />
                          </q-popup-proxy>
                        </q-btn>
                      </template>
                    </template>
                  </c-mobile-table>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style="margin-top:-15px !important;">
                  <c-table
                    ref="apprline2"
                    title="회람"
                    :isTitle="true"
                    tableId="apprline2"
                    topBorderClass="topcolor-orange"
                    :columnSetting="false"
                    :isFullScreen="false"
                    :hideBottom="true"
                    :usePaging="false"
                    :filtering="false"
                    :gridHeightAuto="true"
                    :columns="gridline2.columns"
                    :data="gridline2.data"
                  >
                  </c-table> -->
                </div>
            </template>
          </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="결재 상세내용" class="cardClassDetailForm displayBlockCard" bgClass="purple" titleClass="white">
          <template slot="card-detail">
            <component :is="component" :popupParam.sync="data.approvalParamValue" />
          </template>
        </c-card>
      </div>
    </div>
    <q-dialog v-model="dialogShow" persistent>
      <q-card style="max-width: 350px">
        <q-form ref="editForm">
          <c-card title="서명란" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable" 
                  label="결재" 
                  icon="save" 
                  @btnClicked="saveDialog"/>
                <c-btn label="지우기" color="red" :showLoading="false" @btnClicked="eraseSignature" />
                <c-btn 
                  label="취소" 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="row">
                <div class="col-xs-12 col-sm-12">
                  <VueSignaturePad width="300px" height="300px" ref="signaturePad" />
                </div>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            :flat="true"
            v-if="popupParam.approvalDocType == 'TODO'"
            label="반려"
            icon="remove"
            @btnClicked="returnApprRequest" />
          <c-btn
            :flat="true"
            v-if="popupParam.approvalDocType == 'TODO'"
            label="결재"
            icon="save"
            @btnClicked="saveApprRequest" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import draggable from 'vuedraggable';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'appr-process',
  components: {
      draggable,
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sysApprovalRequestId: '', // 결재요청 일련번호
        approvalDocType: '',
      }),
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  data() {
    return {
      dialogShow: false,
      editable: true,
      component: null, // 콤포넌트
      componentSigh: null,
      detailTypeUrl: '',
      apprLineUrl: '',
      saveApprRequestUrl: '',
      returnApprRequestUrl: '',
      listUrl: '',
      infoUrl: '',
      electronSignature: '',
      returnReasonText: '',
      data: {
        approvalTypeCd: '',  // 결재유형코드
        approvalRequestName: '',  // 결재 요청명
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalStatusCd: '',  // 결재상태
        approvalParam: '',  // 결재파라메터
      },
      gridline: {
        columns: [
          {
            name: 'approvalKindName',
            field: 'approvalKindName',
            label: '',
            align: 'center',
            style: 'width: 80px',
            type: 'custom',
            colClass: 3,
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '',
            align: 'center',
            style: 'width: 120px',
            colClass: 3,
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '',
            align: 'center',
            style: 'width: 80px',
            colClass: 3,
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '',
            align: 'center',
            colClass: 3,
            style: 'width: 80px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'returnReason',
            field: 'returnReason',
            label: '반려사유',
            align: 'left',
            type: 'textarea',
            style: 'width: 160px',
            sortable: false,
            isExistTxt: true,
            disableTarget: 'approvalStatusCd',
            disableCon: 'ASC0000001',
          },
          {
            name: 'approvalDt',
            field: 'approvalDt',
            label: '결재일시',
            align: 'center',
            colClass: 8,
            style: 'width: 160px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridline2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            style: 'width: 40%',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // 환경설정 결재서명여부
      // if (this.$store.getters.user.approveSignature === 'Y' || this.$store.getters.user.approveSignature === 'M') {
      //   this.gridline.columns.push(
      //     {
      //       name: 'click',
      //       field: 'click',
      //       label: '',
      //       type: 'custom',
      //       align: 'center',
      //       colClass: 4,
      //       sortable: false,
      //       component: () => import(`${'@/pages/sys/appr/apprSignaturePop.vue'}`)
      //     },
      //   )
      // }

      this.infoUrl = selectConfig.sys.appr.info.get.url;
      this.listUrl = selectConfig.sys.appr.line.list.url;
      this.detailTypeUrl = selectConfig.sys.appr.type.get.url;
      this.apprLineUrl = selectConfig.sys.appr.line.last.url;
      this.saveApprRequestUrl = transactionConfig.sys.appr.request.update.url;
      this.returnApprRequestUrl = transactionConfig.sys.appr.request.return.url;
      this.componentSigh = () => import(`${'@/pages/sys/appr/apprSignaturePop.vue'}`)
      this.getApprInfo();
      this.getApprLine();
    },
    getApprLine() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.$http.url = this.listUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          let lineData1 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000001' || x.approvalKindCd == 'AKC0000002' || x.approvalKindCd == 'AKC0000003' || x.approvalKindCd == 'AKC0000004' || x.approvalKindCd == 'AKC0000010'
          });
          let lineData2 = _result.data.filter( x => {
            return x.approvalKindCd == 'AKC0000009'
          });
          this.gridline.data = lineData1;
          this.gridline2.data = lineData2;
        }
      },
      () => {
      });
    },
    getApprInfo() {
      this.$http.url = this.infoUrl;
      this.$http.param = {
        sysApprovalRequestId: this.popupParam.sysApprovalRequestId
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.component = () => import(`@/pages${_result.data.approvalUrl}`);
        this.data.approvalParamValue = JSON.parse(_result.data.approvalParam);
        this.$_.extend(this.data.approvalParamValue, {approvalDocType: this.popupParam.approvalDocType});
      },
      () => {
      });
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000001':
        case 'AKC0000010':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    },
    getLineKindColorStatus(_cd) {
      let color = '';
      // ASC0000001 : 결재중, ASC0000002 : 반려, ASC9999999 : 결재완료, ASC0000000 : 결재요청
      switch(_cd) {
        case 'ASC0000001':
          color = 'green-7';
          break;
        case 'ASC0000002':
          color = 'red-7';
          break;
        case 'ASC9999999':
          color = 'blue-7';
          break;
        case 'ASC0000000':
          color = 'grey';
          break;
      }
      return 'text-' + color;
    },
    getLineKindColor2(_cd) {
      let color = '';
      switch(_cd) {
        case '반려':
          color = 'text-weight-bold text-red';
          break;
      }
      return color;
    },
    returnApprRequest() {
      let apprUserInfo = {};
      let returnData = this.gridline.data.filter( x => {
        return x.approvalStatusCd == 'ASC0000001' && x.approvalUserId == this.$store.getters.user.userId
      });
      let returnData2 = this.gridline.data.filter( x => {
        return x.approvalStatusCd == 'ASC0000001' && x.approvalTransferUserId.indexOf(this.$store.getters.user.userId) > -1
      });
      if (returnData.length > 0) {
        apprUserInfo.approvalLineNo = returnData[0].approvalLineNo;
        apprUserInfo.approvalUserId = returnData[0].approvalUserId;
        apprUserInfo.sysApprovalRequestId = returnData[0].sysApprovalRequestId;
        apprUserInfo.approvalStatusCd = 'ASC0000002'; // 반려
        apprUserInfo.returnReason = this.returnReasonText;
        
        if (this.returnReasonText === null || this.returnReasonText == '') {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '반려사유를 입력하세요.', /* 반려사유를 입력하세요. */
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      } else if (returnData2.length > 0) {
        apprUserInfo.approvalLineNo = returnData2[0].approvalLineNo;
        apprUserInfo.approvalUserId = returnData2[0].approvalUserId;
        apprUserInfo.sysApprovalRequestId = returnData2[0].sysApprovalRequestId;
        apprUserInfo.approvalTransferUserId = this.$store.getters.user.userId;
        apprUserInfo.approvalStatusCd = 'ASC0000002'; // 반려
        apprUserInfo.returnReason = this.returnReasonText;
        
        if (this.returnReasonText === null || this.returnReasonText == '') {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '반려사유를 입력하세요.', /* 반려사유를 입력하세요. */
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
      }

      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '반려하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          let apprSubmitUserInfo = this.$_.find(this.gridline.data, { approvalStatusCd: 'ASC0000000' });

          this.$http.url = this.returnApprRequestUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            approvalUser: apprUserInfo,
            approvalSubmit: apprSubmitUserInfo,
            approvalLineModel: this.gridline.data,
            approvalRequestModel: {
              approvalTypeCd: this.data.approvalTypeCd,
              approvalConnId: this.data.approvalConnId,
              approvalStatusCd: 'ASC0000002',
              sysApprovalRequestId: this.popupParam.sysApprovalRequestId,
              approvalRequestName: this.data.approvalRequestName,
            },
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '결재가 반려 처리되었습니다.', /* 결재가 반려 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.$emit('closePopup');
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // 최근결재 사인이미지 불러오기
    setSignature() {
      signaturePad = this.$refs.signaturePad
      this.$http.url = '/api/sys/appr/signature';
      this.$http.isLoading = false;
      this.$http.param = {
        userId: this.$store.getters.user.userId,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          signaturePad.fromDataURL(_result.data);
        }
      })
    },
    saveApprRequest() {
      // 환경설정 결재서명여부
      if (this.$store.getters.user.approveSignature === 'Y' || this.$store.getters.user.approveSignature === 'M') {
        this.dialogShow = true;
        setTimeout(this.setSignature, 500);
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '결재하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            // 상신자 제외 첫번째 결재자에 결재중상태로 변경
            let apprUserInfo = {};
            let apprNextUserInfo = {};
            let apprSubmitUserInfo = {};
            let apprSignature = '';
            signaturePad = this.$refs.signaturePad
            if (signaturePad.saveSignature().isEmpty) {
              apprSignature = ''
            } else {
              apprSignature = signaturePad.saveSignature().data;
            }
            for(var i=0; i<this.gridline.data.length; i++) {
              if (this.gridline.data[i].approvalStatusCd == 'ASC0000001' && this.gridline.data[i].approvalUserId == this.$store.getters.user.userId) { // 결재중인 사용자 결재처리
                apprUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo;
                apprUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
                apprUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
                apprUserInfo.electronSignature = apprSignature;
                apprUserInfo.approvalStatusCd = 'ASC9999999';
                // 다음결재 결재중으로 UPDATE
                if (this.gridline.data[i+1] !== undefined) {
                  apprNextUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo + 1;
                  apprNextUserInfo.approvalUserId = this.gridline.data[i + 1].approvalUserId;
                  apprNextUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
                  apprNextUserInfo.approvalStatusCd = 'ASC0000001';
                }
              } else if (this.gridline.data[i].approvalStatusCd == 'ASC0000001' && this.gridline.data[i].approvalTransferUserId.indexOf(this.$store.getters.user.userId) > -1) { // 결재이양자 대상일 경우
                apprUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo;
                apprUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
                apprUserInfo.approvalTransferUserId = this.$store.getters.user.userId;
                apprUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
                apprUserInfo.electronSignature = apprSignature;
                apprUserInfo.approvalStatusCd = 'ASC9999999';
                // 다음결재 결재중으로 UPDATE
                if (this.gridline.data[i+1] !== undefined) {
                  apprNextUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo + 1;
                  apprNextUserInfo.approvalUserId = this.gridline.data[i + 1].approvalUserId;
                  apprNextUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
                  apprNextUserInfo.approvalStatusCd = 'ASC0000001';
                }
              }
              if (this.gridline.data[i].approvalStatusCd == 'ASC0000000' ) {
                // 결재 상신자
                apprSubmitUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
              }
            }
            this.$http.url = this.saveApprRequestUrl;
            this.$http.type = 'PUT';
            this.$http.param = {
              approvalUser: apprUserInfo,
              approvalNext: apprNextUserInfo,
              approvalSubmit: apprSubmitUserInfo,
              approvalRequestModel: {
                approvalTypeCd: this.data.approvalTypeCd,
                approvalConnId: this.data.approvalConnId,
                approvalRequestName: this.data.approvalRequestName,
              },
            };
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '결재가 정상적으로 처리되었습니다.', /* 결재가 정상적으로 처리되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.$emit('closePopup');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveDialog() {
      let apprUserInfo = {};
      let apprNextUserInfo = {};
      let apprSubmitUserInfo = {};
      for(var i=0; i<this.gridline.data.length; i++) {
        if (this.gridline.data[i].approvalStatusCd == 'ASC0000001' && this.gridline.data[i].approvalUserId == this.$store.getters.user.userId) { // 결재중인 사용자 결재처리
          signaturePad = this.$refs.signaturePad
          let apprSignature = '';
          if (signaturePad.saveSignature().isEmpty) {
            apprSignature = ''
          } else {
            apprSignature = signaturePad.saveSignature().data;
          }
          apprUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo;
          apprUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
          apprUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
          apprUserInfo.electronSignature = apprSignature;
          apprUserInfo.approvalStatusCd = 'ASC9999999';
          // 다음결재 결재중으로 UPDATE
          if (this.gridline.data[i+1] !== undefined) {
            apprNextUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo + 1;
            apprNextUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
            apprNextUserInfo.approvalUserId = this.gridline.data[i + 1].approvalUserId;
            apprNextUserInfo.approvalStatusCd = 'ASC0000001';
          }
        } else if (this.gridline.data[i].approvalStatusCd == 'ASC0000001' && this.gridline.data[i].approvalTransferUserId.indexOf(this.$store.getters.user.userId) > -1) { // 결재이양자 대상일 경우
          signaturePad = this.$refs.signaturePad
          let apprSignature = '';
          if (signaturePad.saveSignature().isEmpty) {
            apprSignature = ''
          } else {
            apprSignature = signaturePad.saveSignature().data;
          }
          
          apprUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo;
          apprUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
          apprUserInfo.approvalTransferUserId = this.$store.getters.user.userId;
          apprUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
          apprUserInfo.electronSignature = apprSignature;
          apprUserInfo.approvalStatusCd = 'ASC9999999';
          // 다음결재 결재중으로 UPDATE
          if (this.gridline.data[i+1] !== undefined) {
            apprNextUserInfo.approvalLineNo = this.gridline.data[i].approvalLineNo + 1;
            apprNextUserInfo.sysApprovalRequestId = this.gridline.data[i].sysApprovalRequestId;
            apprNextUserInfo.approvalUserId = this.gridline.data[i + 1].approvalUserId;
            apprNextUserInfo.approvalStatusCd = 'ASC0000001';
          }
        }
        if (this.gridline.data[i].approvalStatusCd == 'ASC0000000' ) {
          // 결재 상신자
          apprSubmitUserInfo.approvalUserId = this.gridline.data[i].approvalUserId;
        }
      }
      this.$http.url = this.saveApprRequestUrl;
      this.$http.type = 'PUT';
      this.$http.param = {
        approvalUser: apprUserInfo,
        approvalNext: apprNextUserInfo,
        approvalSubmit: apprSubmitUserInfo,
        approvalRequestModel: {
          approvalTypeCd: this.data.approvalTypeCd,
          approvalConnId: this.data.approvalConnId,
          approvalRequestName: this.data.approvalRequestName,
        },
      };
      this.$http.request(() => {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '결재가 정상적으로 처리되었습니다.', /* 결재가 정상적으로 처리되었습니다. */
          type: 'success', // success / info / warning / error
        });
        this.$emit('closePopup');
      });
    },
    closeDialog() {
      this.dialogShow = false;
    },
    eraseSignature() {
      signaturePad.clearSignature();
    },
    callback1(data, rowIndex) {
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>

<style scoped>
.apprGridTxt {
  font-weight: 700;
  font-size: 1.6em;
  margin-top: 10px;
}
.apprGridTxt2 {
  font-weight: 700;
  font-size: 1.3em;
  margin-top: 10px;
}
.font-size-m-up {
  margin-top: 10px !important;
  font-size: 1.4em;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  padding-bottom: 9px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  min-height: 24px;
}
.linehandley {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  cursor: move;
}
.linehandlex {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  opacity: 0.5;
}
.lineclose {
  float: right;
  padding-top: 0px;
  font-size: 20px;
  margin-right: -10px;
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.3;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.linespan1 {
  width: 15% !important;
  text-align: left;
  font-weight: 600;
}
.linespan12 {
  width: 20% !important;
  text-align: left;
}
.linespan13 {
  width: 29% !important;
  text-align: left;
}
.linespan4 {
  min-width: 80px !important;
  text-align: left;
}
.linespanall {
  width: 100% !important;
  text-align: left;
  padding: 10px 0px  0px 10px;
}
.linespan2 {
  min-width: 120px !important;
  text-align: left;
}
.linetext {
  display: inline-block;
}
.list-group-item {
  padding: 0.4rem  1.25rem !important;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.8rem;
}
.appr-line-detail-layer2 {
  margin: 10px !important;
  padding: 5px !important;
  border: 2px solid #7b1fa2;
  border-radius: 14px;
}
</style>